<script>
import ContactUsDataGrid from '@/views/admin/contact-us/components/ContactUsDataGrid.vue'

export default {
  name: 'ManageContactUs',
  components: { ContactUsDataGrid },
}
</script>

<template>
  <ContactUsDataGrid />
</template>

<style scoped lang="scss">

</style>
