<script>
import {
  DxColumn, DxColumnChooser,
  DxDataGrid,
  DxExport,
  DxHeaderFilter, DxItem,
  DxPaging,
  DxScrolling, DxSearchPanel, DxStateStoring,
  DxToolbar,
} from 'devextreme-vue/data-grid'

import datasource from '@/data/contact-us.datasource'

export default {
  name: 'ContactUsDataGrid',
  components: {
    DxStateStoring,
    DxSearchPanel,
    DxItem,
    DxColumnChooser,
    DxHeaderFilter,
    DxToolbar,
    DxColumn,
    DxDataGrid,
    DxPaging,
    DxScrolling,
    DxExport,
  },
  data() {
    return {
      dataSource: datasource.getInstance(this.$store),
    }
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
  },
}
</script>

<template>
  <DxDataGrid
    ref="dataGrid"
    class="sxr-grid caption-left regular-text-color"
    :data-source="dataSource"
    :show-borders="false"
    :show-row-lines="true"
    :show-column-lines="false"
    :remote-operations="true"
    :word-wrap-enabled="true"
    :column-min-width="50"
    :column-auto-width="true"
    :allow-column-reordering="!isCurrentBreakPointXs"
    width="100%"
    height="100%"
  >
    <DxHeaderFilter
      :visible="false"
      :hide-select-all-on-search="false"
    />
    <DxPaging :page-size="25" />
    <DxColumnChooser
      :enabled="!isCurrentBreakPointXs"
      mode="select"
    />
    <DxExport
      :enabled="false"
      :formats="['csv', 'xlsx']"
      :texts="{
        exportAll: 'Export to {0}'
      }"
    />
    <DxScrolling
      mode="virtual"
    />
    <DxStateStoring
      :enabled="false"
      type="localStorage"
      storage-key="contact_us_dg_state_key_1"
    />
    <DxSearchPanel
      :visible="true"
      :highlight-case-sensitive="true"
    />
    <DxToolbar>
      <DxItem
        location="before"
        name="searchPanel"
      />
      <DxItem
        name="columnChooserButton"
      />
      <DxItem
        name="exportButton"
      />
    </DxToolbar>
    <DxColumn
      data-field="id"
      caption="ID"
      sort-order="desc"
      data-type="number"
    />
    <DxColumn
      data-field="user_id"
      caption="User ID"
      data-type="number"
      :visible="false"
    />
    <DxColumn
      data-field="name"
      caption="User Name"
    />
    <DxColumn
      data-field="email"
      caption="Email"
    />
    <DxColumn
      data-field="subject"
      caption="Subject"
    />
    <DxColumn
      data-field="message"
      caption="Message"
    />
    <DxColumn
      data-field="ip"
      caption="IP"
      :visible="false"
    />
    <DxColumn
      data-field="status"
      caption="Status"
      :visible="false"
    />
    <DxColumn
      data-field="created_at"
      caption="Created At"
      :min-width="160"
      data-type="datetime"
    />
    <DxColumn
      data-field="updated_at"
      caption="Updated At"
      :min-width="160"
      data-type="datetime"
      :visible="false"
    />
  </DxDataGrid>
</template>

<style lang="scss">
@import '@/assets/scss/common/grid.scss';
.dark-layout .dx-popup-wrapper > .dx-overlay-content {
  border: 1px solid #404656;
  background: #353d55;
}
</style>
<style scoped lang="scss">

</style>
